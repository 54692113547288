import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

const theme = {
  colors: {
    primary: '#7c9885',
    secondary: '#5e7a6a',
    text: '#333',
    background: '#faf9f7',
    accent: '#d6ad60',
  },
  fonts: {
    main: "'Raleway', sans-serif",
    heading: "'Playfair Display', serif",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Hero />
      <About />
      <Services />
      <Portfolio />
      <Team />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
}

export default App;