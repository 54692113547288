import React from 'react';
import styled from 'styled-components';

const PortfolioSection = styled.section`
  text-align: center;
`;

const PortfolioTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const PortfolioItem = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

function Portfolio() {
  const portfolioItems = [
    {
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/rz85qfd3htnalp1wbcys.jpg",
      alt: "Luxury Living Room"
    },
    {
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726742216/wng2hyjapwclavveyhfh.jpg",
      alt: "Modern Kitchen"
    },
    {
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/yo0frwyyhhsofurqahxq.jpg",
      alt: "Serene Bedroom"
    },
    {
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/dly6e3e1ip876qhsqzvr.jpg",
      alt: "Elegant Bathroom"
    }
  ];

  return (
    <PortfolioSection id="portfolio">
      <PortfolioTitle>Featured Projects</PortfolioTitle>
      <PortfolioGrid>
        {portfolioItems.map((item, index) => (
          <PortfolioItem key={index} src={item.src} alt={item.alt} />
        ))}
      </PortfolioGrid>
    </PortfolioSection>
  );
}

export default Portfolio;