import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  position: relative;
  height: 80vh;
  overflow: hidden;
`;

const CarouselContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CarouselSlide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.background};
  z-index: 1;
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.a`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-weight: bold;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const DotsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.background};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

function Hero() {
  const images = [
    'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/hueqo6wna0xufekjesvi.jpg',
    'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737928/hggev3f3do2rzehfsjco.jpg',
    'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/ws5f11rrrqrjsdmswyzg.jpg',
    'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737928/biy5zmplkqmqbzcffn7m.jpg',
    'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/yo0frwyyhhsofurqahxq.jpg'
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <HeroSection id="hero">
      <CarouselContainer>
        {images.map((image, index) => (
          <CarouselSlide
            key={index}
            image={image}
            active={index === currentSlide}
          />
        ))}
      </CarouselContainer>
      <Overlay />
      <ContentWrapper>
        <HeroTitle>Understated Luxury</HeroTitle>
        <HeroSubtitle>Elevating spaces with curated design</HeroSubtitle>
        <CTAButton href="#contact">Schedule a Consultation</CTAButton>
      </ContentWrapper>
      <DotsContainer>
        {images.map((_, index) => (
          <Dot
            key={index}
            active={index === currentSlide}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </DotsContainer>
    </HeroSection>
  );
}

export default Hero;