import React from 'react';
import styled from 'styled-components';
//test
const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.background};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.img`
  height: 50px;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin-left: 2rem;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const CTAButton = styled(NavLink)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  padding: 0.5rem 1rem;
  border-radius: 5px;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.background};
  }
`;

function Header() {
  return (
    <HeaderWrapper>
      <Nav>
        <Logo src='https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/lbygznr61vxgmxolnjbn.jpg' alt="Urbanology Designs Logo" />
        <NavList>
          <NavItem><NavLink href="#about">About</NavLink></NavItem>
          <NavItem><NavLink href="#services">Services</NavLink></NavItem>
          <NavItem><NavLink href="#portfolio">Portfolio</NavLink></NavItem>
          <NavItem><NavLink href="#team">Team</NavLink></NavItem>
          <NavItem><CTAButton href="#contact">Contact</CTAButton></NavItem>
        </NavList>
      </Nav>
    </HeaderWrapper>
  );
}

export default Header;