import React from 'react';
import styled from 'styled-components';
import { FaHome, FaPencilRuler, FaCouch } from 'react-icons/fa';

const ServicesSection = styled.section`
  text-align: center;
`;

const ServicesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled.div`
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const ServiceTitle = styled.h3`
  margin-bottom: 1rem;
`;

function Services() {
  return (
    <ServicesSection id="services">
      <ServicesTitle>Our Services</ServicesTitle>
      <ServiceGrid>
        <ServiceCard>
          <ServiceIcon><FaHome /></ServiceIcon>
          <ServiceTitle>Full Home Design</ServiceTitle>
          <p>Comprehensive interior design for your entire living space.</p>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon><FaPencilRuler /></ServiceIcon>
          <ServiceTitle>Room Refresh</ServiceTitle>
          <p>Revitalize a single room with our expert touch.</p>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon><FaCouch /></ServiceIcon>
          <ServiceTitle>Custom Furnishings</ServiceTitle>
          <p>Bespoke furniture pieces tailored to your style.</p>
        </ServiceCard>
      </ServiceGrid>
    </ServicesSection>
  );
}

export default Services;