import React from 'react';
import styled from 'styled-components';

const TeamSection = styled.section`
  text-align: center;
`;

const TeamTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const TeamMember = styled.div`
  text-align: center;
`;

const TeamPhoto = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const TeamMemberName = styled.h3`
  margin-bottom: 0.5rem;
`;

const TeamMemberTitle = styled.p`
  color: ${props => props.theme.colors.primary};
`;

function Team() {
  const teamMembers = [
    {
      name: "Ginger Curtis",
      title: "Founder & Principal Designer",
      photo: "path_to_ginger_photo.jpg"
    },
    {
      name: "Martina Abdelkedous",
      title: "Design Manager",
      photo: "path_to_martina_photo.jpg"
    }
  ];

  return (
    <TeamSection id="team">
      <TeamTitle>Meet Our Team</TeamTitle>
      <TeamGrid>
        {teamMembers.map((member, index) => (
          <TeamMember key={index}>
            <TeamPhoto src={member.photo} alt={member.name} />
            <TeamMemberName>{member.name}</TeamMemberName>
            <TeamMemberTitle>{member.title}</TeamMemberTitle>
          </TeamMember>
        ))}
      </TeamGrid>
    </TeamSection>
  );
}

export default Team;